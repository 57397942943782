import styled from 'styled-components';

const MainSection = styled.main`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  background: #000;
  color: #f0f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
  min-height: calc(100vh - 155px);
  @media (max-width: 600px) {
    min-height: calc(100vh - 70px);
    margin-top: 70px;
    flex-direction: ${props => (props.stackOnMobile ? 'column' : 'row')};
  }

  .download-links {
    a {
      display: inline-block;
      padding: 15px;
      box-sizing: border-box;
      img {
        width: auto;
        height: 60px;
      }
    }
  }

  .blurb-container,
  .image-container {
    position: relative;
  }
  .image-container {
    @media (min-width: 600px) {
      order: 2;
    }
    @media (max-width: 599px) {
      width: 250px;
    }

    flex-basis: 350px;
  }
  .blurb-container {
    text-align: center;
  }
  .container {
    text-align: center;
    position: relative;
  }
  .logo-container {
    max-width: 100%;
    margin-bottom: 1rem;
    margin: 0 auto;
  }
  h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    margin-bottom: 0;
  }
  .content {
    max-width: 500px;
    margin: 0 auto;
    padding: 15px;
  }
  .social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    li {
      padding: 15px;
      img {
        width: 50px;
      }
    }
  }
`;

export default MainSection;
